import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/main.css';
import vuetify from './plugins/vuetify'
import Vue2TouchEvents from 'vue2-touch-events'

//import axios from 'axios'

//axios.defaults.withCredentials = true


Vue.config.productionTip = false

const DEFAULT_TITLE = "Sabrina & Silas";
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

new Vue({
  router,
  Vue2TouchEvents,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
