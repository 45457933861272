import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'


Vue.use(Vuetify, {
    options: {
        customProperties: true
    }
})

const opts = {
    customProperties: true,
    theme: {
        themes: {
            light: {
                primary: '#000000',
                secondary: '#D3D3D3',
                accent: '#8c9eff',
                error: '#b71c1c'
            },
            dark: {
                primary: '#FFFFFF',
                secondary: '#D3D3D3',
                accent: '#8c9eff',
                error: '#b71c1c'
            },
        },
        dark: false,
    }
}

export default new Vuetify(opts)
