<template>
  <v-app>


    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid class="ma-0 pa-0" style="overflow-y: hidden">

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>

export default {
  components: {
  }
}
</script>

<style>
@font-face {
  font-family: "DancingScript";
  src: url(./fonts/Dancing_Script/DancingScript-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "WindSong";
  src: url(./fonts/WindSong/WindSong-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "WindSong";
  src: url(./fonts/WindSong/WindSong-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url(./fonts/Raleway/Raleway-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url(./fonts/Raleway/Raleway-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url(./fonts/Raleway/Raleway-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url(./fonts/Raleway/Raleway-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url(./fonts/Raleway/Raleway-Italic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Cherolina";
  src: url(./fonts/cherolina/Cherolina.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
