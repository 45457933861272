import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index.js'


const routerOptions = [
    {
        name: 'Home',
        path: '/',
        component: 'Home/Home',
        meta: {title: 'Sabrina & Silas'}
    },
    {
        name: 'News',
        path: '/news',
        component: 'News/News',
        meta: {title: 'S & S - News'}
    },
    {
        name: 'ZivileTrauung',
        path: '/ziviletrauung',
        component: 'Fest/Standesamt',
        meta: {title: 'S & S - Standesamtliche Trauung', requiresAuth: true}
    },
    {
        name: 'Brautpaar',
        path: '/brautpaar',
        component: 'Brautpaar/Brautpaar',
        meta: {title: 'S & S - Brautpaar', requiresAuth: true}
    },
    {
        name: 'NiceToKnow',
        path: '/nice-to-know',
        component: 'NiceToKnow/NiceToKnow',
        meta: {title: 'S & S - Nice to Know', requiresAuth: true}
    },
    {
        name: 'Anmeldung',
        path: '/anmeldung',
        component: 'Anmeldung/Anmeldung',
        meta: {title: 'S & S - Anmeldung', requiresAuth: true}
    },
    {
        name: 'Galerie',
        path: '/galerie',
        component: 'Galerie/Galerie',
        meta: {title: 'S & S - Galerie'}
    }
    // {
    //     name: 'Trauzeugen',
    //     path: '/trauzeugen',
    //     component: 'Trauzeugen/Trauzeugen',
    //     meta: {title: 'S & S - Trauzeugen', requiresAuth: true}
    // },
]


const routes = routerOptions.map((route) => {
    return {
        ...route,
        component: () => import(`../components/${route.component}.vue`)
    }
})

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
        next('/');
    } else {
        next();
    }
});

export default router;

