import axios from 'axios';
import router from "../../router";
const state = {
    isLoggedIn: null
};
const getters = {
    isAuthenticated: state => !!state.isLoggedIn

};
const actions = {
    // eslint-disable-next-line no-unused-vars
    async LogIn({commit}, formdata) {
        await axios
            .post("https://sabrinaundsilas-production-d248.up.railway.app/api/user/login", formdata, {
                headers: {
                    // Overwrite Axios's automatically set Content-Type
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                if (res.data == true) {
                    commit('setAuthentication', res.data)
                }
            })
    },
    async LogOut({commit}){
        let user = null
        commit('LogOut', user)
        await router.push('/')
    },
};
const mutations = {
        setAuthentication(state, isAuthenticated) {
            state.isLoggedIn = isAuthenticated;
        },
        LogOut(state){
            state.isLoggedIn = false
        },
};
export default {
    state,
    getters,
    actions,
    mutations
};
